import { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { getInterventions, updateIntervention } from 'api/interventions.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import { ManualAlertTypes } from 'constants/enums.js';
import SocketEvents from 'constants/socket-events.js';
import SpeechToText from 'SpeechToText.jsx';
import { actionCreators as patientNotesActionCreators } from 'state/patientNotes/actions.js';
import { useDispatch } from 'react-redux';
import { Alert, CustomTextarea, Grid, Loader } from 'components/index.js';
import { NotesIcon, SpinLoader } from 'icons/Monitoring/index.js';
import translate from 'i18n-translations/translate.jsx';
import Button from 'components/Button.jsx';
import { getDeviceOwnerPatient } from 'api/patients.js';
import { MainAlertType } from 'constants/alerts.js';

const Drafts = ({ patientId, deviceId }) => {
	const intl = useIntl();
	const [selectedItem, setSelectedItem] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [interventions, setInterventions] = useState([]);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const [error, setError] = useState('');
	const [loadingIds, setLoadingIds] = useState([]);
	const socket = useContext(SocketContext);
	const [recognizedTranscription, setRecognizedTranscription] = useState('');
	const [liveTranscription, setLiveTranscription] = useState('');
	const [isSpeechToText, setIsSpeechToText] = useState(false);
	const hasReachedEnd = useRef(null);
	const dispatch = useDispatch();
	const [isDraftSaving, setIsDraftSaving] = useState(false);

	const handleSave = async item => {
		if (isDraftSaving) {
			return;
		}
		setLoadingIds(prevState => [...prevState, item.id]);
		setSelectedItem(prevState => ({ ...prevState, isLoading: true }));
		const { id, mainAlertType } = item;
		const response = await updateIntervention(patientId, deviceId, {
			id,
			comment: recognizedTranscription,
			mainAlertType,
		});
		if (response.error) {
			setIsDraftSaving(false);
			setError(response.error.message);
			return;
		}
		if (interventions.length === 1) {
			setPagination(prevState => ({ ...prevState, pageIndex: -1 }));
		}
		dispatch(patientNotesActionCreators.decreaseDraftsCount(null, deviceId));
		setInterventions(prevState => prevState.filter(intervention => intervention.id !== id));
		setSelectedItem(null);
		setLoadingIds(prevState => prevState.filter(id => id !== item.id));
		setIsDraftSaving(false);
	};

	const getlastActivityType = activityType => {
		switch (activityType) {
			case 5:
				return 'Acknowledge Alert';
			case 6:
				return 'False Alert';
			case 7:
				return 'Forward Alert';
			default:
				return '';
		}
	};

	const getDraftDescription = item => {
		if (item.typeId === ManualAlertTypes.PRIVACY_MODE_ON) {
			return 'Privacy mode on';
		}
		if (item.typeId === ManualAlertTypes.PRIVACY_MODE_OFF) {
			return 'Privacy mode off';
		}
		if (item.typeId === ManualAlertTypes.START_STAT_ALARM) {
			return 'Stat alarm has started';
		}
		if (item.typeId === ManualAlertTypes.STOP_STAT_ALARM) {
			return 'Stat alarm has stopped';
		}
		if (item.mainAlertType === MainAlertType.AI) {
			return `${intl.formatMessage({ id: item.typeName })} (${getlastActivityType(item.lastActivityType)})`;
		}
		return item.typeName;
	};

	const mapInterventions = arr => {
		let result = [];
		if (!arr) {
			return result;
		}
		arr.forEach(item => {
			if (item.properties) {
				result.push({ ...item, comment: '' });
			} else {
				result.push(item);
			}
		});
		return result;
	};

	useEffect(() => {
		const fetchIntervetions = async () => {
			if (!deviceId) {
				setInterventions([]);
				setIsLoading(false);
				return;
			}
			let existingPatientId = patientId;
			if (!patientId) {
				const deviceOwnerResponse = await getDeviceOwnerPatient(deviceId);
				if (deviceOwnerResponse.error) {
					setError(deviceOwnerResponse.error.message);
					return;
				}
				existingPatientId = deviceOwnerResponse?.healthcareUserId;
			}
			if (!existingPatientId) {
				setInterventions([]);
				setIsLoading(false);
				return;
			}
			const params = {
				patientId: existingPatientId,
				deviceId,
				isDraft: true,
				pageIndex: pagination.pageIndex === -1 ? 0 : pagination.pageIndex,
				pageSize: pagination.pageSize,
			};
			const response = await getInterventions(params);
			if (response.error) {
				setError(response.error.message);
				setInterventions([]);
				setIsLoading(false);
				return;
			}
			const interventionsMapped = mapInterventions(response.devicePatientInterventions);
			if (pagination.pageIndex === 0) {
				setInterventions(interventionsMapped);
			} else {
				setInterventions(prevState => [...prevState, ...interventionsMapped]);
			}
			setIsLoading(false);
			setPagination(prevState => ({ ...prevState, totalCount: response.totalCount || 0 }));
			hasReachedEnd.current = response?.devicePatientInterventions?.length < 10;
		};
		fetchIntervetions();
	}, [deviceId, patientId, pagination.pageIndex]);

	useEffect(() => {
		const handleAlertsResponse = async data => {
			const { alertId, alertTypeName, createdAt, manualAlertTypeId, isDraft } = data;
			if (!isDraft) {
				return;
			}
			const result = {
				dateCreated: createdAt,
				id: alertId,
				mainAlertType: MainAlertType.MANUAL,
				typeId: manualAlertTypeId,
				typeName: alertTypeName,
			};
			setInterventions(prevState => [result, ...prevState]);
		};
		const handleAutomaticAlertsResponse = async data => {
			const { alertId, alertTypeName, createdAt, manualAlertTypeId, aiAlertActivityTypeId } = data;
			const result = {
				dateCreated: createdAt,
				id: alertId,
				mainAlertType: MainAlertType.AI,
				typeId: manualAlertTypeId,
				typeName: alertTypeName,
				lastActivityType: aiAlertActivityTypeId,
			};
			setInterventions(prevState => [result, ...prevState]);
		};
		const handleInterventionUpdated = data => {
			if (deviceId === data.deviceId && data.id && interventions.find(item => item.id === data.interventionId)) {
				setInterventions(prevState => prevState.filter(item => item.id !== data.interventionId));
			}
		};
		socket.on(SocketEvents.Alerts.NEW_AI_ALERT_ACTIVITY, handleAutomaticAlertsResponse);
		socket.on(SocketEvents.HealthCare.ALERTS_MANUAL_ADDED, handleAlertsResponse);
		socket.on(SocketEvents.Alerts.ALERTS_INTERVENTION_UPDATED, handleInterventionUpdated);
		return () => {
			socket.off(SocketEvents.Alerts.NEW_AI_ALERT_ACTIVITY, handleAutomaticAlertsResponse);
			socket.off(SocketEvents.HealthCare.ALERTS_MANUAL_ADDED, handleAlertsResponse);
			socket.off(SocketEvents.Alerts.ALERTS_INTERVENTION_UPDATED, handleInterventionUpdated);
		};
	}, [patientId, interventions]);

	const handleScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !hasReachedEnd.current) {
			setPagination(prevState => ({ ...prevState, pageIndex: prevState.pageIndex + 1 }));
		}
	};

	return (
		<div className='interventions-draft-items box-max-height' onScroll={handleScroll}>
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='250px' horizAlign='center' vertAlign='center'>
					<div style={{ textAlign: 'center' }}>
						<Loader />
					</div>
				</Grid>
			)}
			{!isLoading && (
				<>
					{interventions.map(item => (
						<div className={classNames('draft-item', selectedItem?.id === item.id ? 'no-hover' : '')}>
							<div className='flex flex-space-between flex-align-center'>
								<div className={selectedItem?.id === item.id ? 'flex flex-align-center' : ''}>
									<h3>{getDraftDescription(item)}</h3>
									<p>{formattedDate(item.dateCreated)}</p>
								</div>
								<div className='flex flex-space-between flex-align-center draft-item-actions'>
									<>
										{selectedItem?.id !== item.id && (
											<>
												<Button
													alt='notes'
													onClick={() => {
														setSelectedItem(item);
														setRecognizedTranscription('');
													}}
													svgIcon={<NotesIcon width={14} height={14} />}
												/>
												<Button
													alt='notes'
													onClick={() => {
														setIsDraftSaving(true);
														handleSave(item);
													}}
													svgIcon={
														!loadingIds.includes(item.id) ? <i className='material-icons-outlined'>done</i> : <SpinLoader />
													}
												/>
											</>
										)}
									</>
									{selectedItem?.id === item.id && <Button onClick={() => setSelectedItem(null)} icon='close' />}
								</div>
							</div>
							{selectedItem?.id === item.id && !selectedItem?.isLoading && (
								<CustomTextarea
									maxLength={199}
									onChange={event => setRecognizedTranscription(event.target.value)}
									value={isSpeechToText ? `${recognizedTranscription} ${liveTranscription}`.trim() : recognizedTranscription}
									maxNumberLimit={40}
									rows={5}
									name='text'
									placeholder={intl.formatMessage({ id: 'addAdditionalNotes' })}
									charactersCounter={false}
									icon={
										<SpeechToText
											setRecognizedTranscription={setRecognizedTranscription}
											setLiveTranscription={setLiveTranscription}
											setIsSpeechToText={setIsSpeechToText}
											isSpeechToText={isSpeechToText}
										/>
									}
									saveBtn={
										<Button
											icon='done'
											text={translate('save')}
											onClick={() => {
												setIsDraftSaving(true);
												handleSave(item);
											}}
										/>
									}
								/>
							)}
						</div>
					))}
				</>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default Drafts;
