import React, { useContext, useEffect, useRef, useState } from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { isChrome, isEdgeChromium, isMobileSafari } from 'react-device-detect';
import classNames from 'classnames';

import {
	LocalTrackAdded,
	LocalTrackRemoved,
	RemoteTrackAdded,
	RemoteTrackRemoved,
	Mic,
	Cam,
	ScreenShare,
} from '@solaborate/calls/webrtc';
import { conference as Conference, enums, participant as Participant } from '@solaborate/calls';
import {
	MainParticipantView,
	CallControlsView,
	ParticipantsView,
	InviteParticipantsView,
	StreamSettingsView,
	VirtualBackgroundView,
	PictureInPictureCameraView,
} from 'calls/views/index.js';
import {
	useConference,
	useConferenceConfigurations,
	useConferenceParticipants,
	useConferenceState,
	useLocalParticipant,
	useScreenType,
} from 'calls/hooks/index.js';
import { Toaster, Popup, Button, Modal, RoundingAi } from 'calls/components/index.js';
import {
	callTypeToTrackTypes,
	getUserRoleId,
	captureFrame,
	getConferenceEndedTimeout,
	isMedicalInfoModalsOpen,
	changeLocalParticipantBackground,
	findPatientRoleParticipant,
	findRemoteHelloParticipant,
} from 'calls/helpers/index.js';
import LightTheme from 'calls/styles/LightTheme.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import {
	ConferenceEndReasonMessages,
	ControlsActions,
	StartQueryStringKeys,
	UserTypes,
	AdditionalData,
	WindowSize,
} from 'calls/enums/index.js';
import RemoteParticipant from 'calls/RemoteParticipant.js';
import RemoteHelloParticipant from 'calls/RemoteHelloParticipant.js';
import { VirtualBackgroundProvider } from 'calls/views/VirtualBackgroundProvider.jsx';

// #region Healthcare
import HealthDataContainer from 'containers/HealthDataContainer.jsx';
import CameraMeasurements from 'containers/NewCameraMeasurements.jsx';
import AssignedRequestConferenceFunctions from 'components/AssignedRequestConferenceFunctions.jsx';
import translate from 'i18n-translations/translate.jsx';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import {
	StreamError,
	MediaPermissions,
	MediaTypes,
	UserPermissionDeniedErrors,
	ErrorComponentTypes,
	UserRoles,
	JoinConferenceFailureReasonEnum,
	AppointmentStatus,
	HealthSystemType,
	ButtonType,
	IframeIntregrationsIds,
	ObjectType,
	VisitReasons,
	CallTypes,
} from 'constants/enums.js';
import { getCompanyId, getUserRole } from 'infrastructure/auth.js';
import {
	checkForPermission,
	checkIfMediaDevicesPlugged,
	isSmartLauncher,
	isSessionEhr,
	getRoleConfigurationValue,
	findSectorById,
	getFailedInvitationMessage,
	getUserBackgroundParams,
	getDescFromEnum,
	getStorage,
} from 'infrastructure/helpers/commonHelpers.js';
import { APP_CONFIG } from 'constants/global-variables.js';
import { sendDoctorRequest } from 'api/doctorRequests.js';
import LOCALES from 'i18n-translations/translate.jsx';
import VisitorContainer from 'containers/VisitorContainer.jsx';
import { busySound, dropSound, outGoingCallSound, stopOutgoingCallSound } from 'components/CallSounds.jsx';
import { RoundingSettings } from 'constants/configurationEnums.js';
import { RoomTypes } from 'constants/visitEnums.js';
import { updateAppointmentStatus } from 'api/appointments.js';
import { saveVisitEndDateTime } from 'api/medicalInfo.js';
import { updateMedicalVisitStatus } from 'api/visits.js';
import queryString from 'query-string';
import { getCurrentSessionPatientData, getRoomInfo } from 'api/ehr.js';
import { v4 as uuidv4 } from 'uuid';
import RemoveParticipantModal from 'components/Modal.jsx';
import { getDeviceOwnerPatient, getOwnerMrn, getPatientByUserId } from 'api/patients.js';
import Alert from 'components/Alert.jsx';
import Star from 'icons/Rounding/Star.jsx';
import { sendFeedBack } from 'api/callStats.js';
import SpeechToTextView from 'calls/views/SpeechToTextView.jsx';
import { getRequest } from 'api/patientRequests.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import CustomButton from 'components/Button.jsx';
import { getMonthDayYearDateFormat } from 'infrastructure/helpers/dateHelper.js';
// #endregion Healthcare

const MediaBlockedPopup = (camera, microphone) => {
	const intl = useIntl();
	return (
		<Popup containerWidth={600} background='rgba(0,0,0,.6)'>
			<img src='https://static.solaborate.com/banyan/banyan-camera-permission.svg' alt='Microphone permission browser' />
			<h3>
				{camera && microphone && translate('cameraAndMicBlocked')}
				{camera && !microphone && translate('cameraBlocked')}
				{!camera && microphone && translate('micBlocked')}
			</h3>
			<p>
				{translate('clickMicOrCamera', {
					value: intl.formatMessage({ id: !camera && microphone ? 'microphone' : 'camera' }),
				})}
			</p>
			<p>{translate('allowAccessRefreshPage')}</p>
		</Popup>
	);
};

const RateCallQuality = () => {
	const feedBackArr = [
		{ id: 1, description: translate('veryPoor') },
		{ id: 2, description: translate('poor') },
		{ id: 3, description: translate('good') },
		{ id: 4, description: translate('veryGood') },
		{ id: 5, description: translate('excellent') },
	];
	const darkMode = useSelector(state => state.user.darkMode);
	const [selectedStar, setSelectedStar] = useState(null);
	const [hoveredId, setHoveredId] = useState(null);
	const [comment, setComment] = useState('');
	const conference = useConference();
	const localParticipant = useLocalParticipant();

	const submitCallQuality = async () => {
		if (!selectedStar) {
			window.close();
			return;
		}
		const params = {
			conferenceId: conference.conferenceId,
			participantId: localParticipant.id,
			rate: selectedStar,
			comment,
		};
		await sendFeedBack(params);
		window.close();
	};

	return (
		<Popup containerWidth={600} background={darkMode ? LightTheme.colors.graySix : LightTheme.colors.grayOne}>
			<div className={classNames('call-feedback', darkMode ? 'dark-mode' : '')}>
				<div className='flex feedback-title'>
					<h4>{translate('howWasQuality')}</h4>
					<span className='modal__close cursor-pointer' onClick={() => window.close()}>
						<i className='material-icons-outlined'>close</i>
					</span>
				</div>
				<div className='flex stars'>
					{feedBackArr.map(item => (
						<div
							key={item.id}
							onClick={() => setSelectedStar(item.id)}
							onMouseEnter={() => setHoveredId(item.id)}
							onMouseLeave={() => setHoveredId(null)}>
							<Star
								color={selectedStar >= item.id || hoveredId >= item.id ? LightTheme.colors.blueOne : LightTheme.colors.grayTwo}
							/>
							<p>{item.description}</p>
						</div>
					))}
				</div>
				{selectedStar && (
					<div>
						<textarea
							value={comment}
							onChange={event => setComment(event.target.value)}
							className='flex call-feedback-textarea'></textarea>
					</div>
				)}
				<div className='flex'>
					<CustomButton
						className='call-feedback-btn flex-justify-center'
						onClick={submitCallQuality}
						text={translate(selectedStar ? 'submit' : 'dismiss')}
					/>
				</div>
			</div>
		</Popup>
	);
};

const CallEnded = ({ refToken = '', busyVisitorParticipant = null, failedInviteMessage }) => {
	const conferenceConfigs = useConferenceConfigurations();
	const history = useHistory();
	const conference = useConference();
	const localParticipant = useLocalParticipant();
	const conferenceState = useConferenceState();

	if (!(conferenceState instanceof Conference.StateEnded)) {
		return null;
	}
	const getConferenceEndedMessage = () => {
		if (conferenceState.endReason === enums.ConferenceEndReasons.PARTICIPANT_INVITE_FAILED) {
			return failedInviteMessage;
		}
		if (getUserRole() === UserRoles.VISITOR) {
			// familymember
			if (conferenceState.endReason === enums.ConferenceEndReasons.PARTICIPANT_BUSY) {
				if (busyVisitorParticipant) {
					return translate('babyRoomDeviceBusy', { value: busyVisitorParticipant.name });
				}
				return translate('deviceIsBusy');
			}

			if (conferenceState.endReason === enums.ConferenceEndReasons.REMOVED) {
				return translate('youAreRemovedFromConference');
			}

			if (
				conferenceState.endReason !== enums.ConferenceEndReasons.TERMINATED_BY_ADMINISTRATOR &&
				conferenceState.endReason !== enums.ConferenceEndReasons.PARTICIPANT_DECLINED
			) {
				return translate('callEnded');
			}

			return conferenceState.endReason === enums.ConferenceEndReasons.TERMINATED_BY_ADMINISTRATOR
				? translate('meetingEndedFamilyMember', { value: conferenceConfigs.helloName })
				: translate('babyOutsideVisitingHoursMessage');
		}

		if (conferenceState.endReason === enums.ConferenceEndReasons.PARTICIPANT_LEFT && localParticipant.isGuest) {
			return translate('youHaveLeftMeeting');
		}

		if (conferenceState.endReason === enums.ConferenceEndReasons.REMOVED) {
			window.history.replaceState({}, document.title);
			return translate('participantRemovedByAdministrator');
		}

		return ConferenceEndReasonMessages[conferenceState.endReason];
	};

	const handleOnRejoin = () => {
		if (getUserRole() === UserRoles.GUEST) {
			getStorage().setItem('virtualBackground', 'none');
		}
		history.replace(conference.link.replace(/^.*\/\/[^/]+/, ''));
	};

	return (
		<Popup containerWidth={600} background={LightTheme.colors.blueThree}>
			<h4>{getConferenceEndedMessage()}</h4>
			{conferenceState.endReason === enums.ConferenceEndReasons.PARTICIPANT_LEFT && localParticipant.isGuest && !refToken && (
				<Button variant={ButtonType.SUBMIT} onClick={handleOnRejoin}>
					{translate('rejoin')}
				</Button>
			)}
		</Popup>
	);
};

const deviceUnavailable = endReason =>
	[
		enums.ConferenceEndReasons.PARTICIPANT_OFFLINE,
		enums.ConferenceEndReasons.PARTICIPANT_BUSY,
		enums.ConferenceEndReasons.PARTICIPANT_NOT_ANSWERING,
		enums.ConferenceEndReasons.PARTICIPANT_DECLINED,
		enums.ConferenceEndReasons.HAS_ACTIVE_CONFERENCE,
		enums.ConferenceEndReasons.FAILED_TO_GET_INITIATOR_INFO,
		enums.ConferenceEndReasons.PARTICIPANT_INVITE_FAILED,
		enums.ConferenceEndReasons.PARTICIPANT_INVITE_DENIED,
		enums.ConferenceEndReasons.OWNER_LEFT,
		enums.ConferenceEndReasons.TERMINATED_BY_ADMINISTRATOR,
	].includes(endReason);

const ConferenceEndedMessage = ({ refToken = '', busyVisitorParticipant = null, failedInviteMessage }) => {
	const roleRoundingConfigurations = useSelector(state => state.configurations.roleRoundingConfigurations);
	const conferenceState = useConferenceState();

	if (
		deviceUnavailable(conferenceState?.endReason) &&
		getRoleConfigurationValue(roleRoundingConfigurations, RoundingSettings.RateCall)
	) {
		return (
			<Popup containerWidth={600} background={LightTheme.colors.blueThree}>
				<h4>{ConferenceEndReasonMessages[conferenceState?.endReason]}</h4>
			</Popup>
		);
	}

	return (
		<>
			{getRoleConfigurationValue(roleRoundingConfigurations, RoundingSettings.RateCall) && <RateCallQuality />}
			{!getRoleConfigurationValue(roleRoundingConfigurations, RoundingSettings.RateCall) && (
				<CallEnded
					refToken={refToken}
					busyVisitorParticipant={busyVisitorParticipant}
					failedInviteMessage={failedInviteMessage}
				/>
			)}
		</>
	);
};

/**
 * @type {import('styled-components').StyledComponent<"div", any, { $isCursorStill: boolean, $isDarkMode: boolean, $isViewPatient: boolean, $isGridView: boolean, $gridCount: number, $isPatientMeasurementsOpen: boolean, $isMedicalInfoOpen: boolean, $isInviteParticipantsModalViewOpen: boolean, $isMoreParticipantsViewOpen: boolean, $isMultiBed: boolean, $isWhiteboardVisible: boolean, $isLiveCaptionsOpen: boolean, $isAiOpen: boolean, $isRoomSignOpen: boolean }, never>}
 */
const StyledConference = styled.div`
	width: 100%;
	height: calc(100vh - 60px);
	padding-bottom: var(--spacing-s);
	background: ${props => (props.$isDarkMode ? DarkTheme.colors.grayFour : LightTheme.colors.grayTen)};
	overflow: hidden;

	~ .conversation-modal-visible .right-side.measurement-right-side,
	~ .right-side {
		top: 0 !important;
		right: 0 !important;
		height: 100%;
		padding: ${LightTheme.spacing[4]}px;
		z-index: 1;
		border-radius: 0;
		width: 400px;
		&:not(.conference-whiteboard-aside) {
			padding: ${LightTheme.spacing[4]}px;
		}

		h4 {
			padding: 0;
			font-size: 16px;
			margin-bottom: ${LightTheme.spacing[2]}px;
		}
	}

	.participants-wrapper {
		height: 100%;
		width: fit-content;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;

		&:not(:has(> aside)) {
			> main {
				width: calc(16 / 9 * 100vh);
			}
		}

		${props =>
			props.$gridCount <= 2 &&
			css`
				position: relative;
			`};

		${props =>
			(props.$isInviteParticipantsModalViewOpen ||
				props.$isLiveCaptionsOpen ||
				props.$isMedicalInfoOpen ||
				props.$isPatientMeasurementsOpen ||
				props.$isMoreParticipantsViewOpen ||
				props.$isWhiteboardVisible ||
				props.$isAiOpen ||
				props.$isRoomSignOpen) &&
			css`
				width: ${props.$isPatientMeasurementsOpen ? 50 : 70}%;
				${props.$isInviteParticipantsModalViewOpen && props.$isPatientMeasurementsOpen && `width: 70%;`}
			`};

		> main {
			width: calc((16 / 9) * (100vh - 170px));
			max-width: 100vw;
			height: calc((9 / 16) * 100vw);
			max-height: calc(100vh - 170px);
			background: ${props => (props.$isDarkMode ? DarkTheme.colors.grayThree : LightTheme.colors.grayFourteen)};
			position: relative;
			display: flex;
			align-self: center;
			align-items: center;
			justify-content: center;
			overflow: hidden;

			@media (min-height: 700px) {
				width: calc((16 / 9) * (100vh - 190px));
				max-height: calc(100vh - 190px);
			}

			@media (min-height: 800px) {
				width: calc((16 / 9) * (100vh - 200px));
				max-height: calc(100vh - 200px);
			}

			&:not(:has(> video)) {
				display: flex;
				align-items: center;
				justify-content: center;
				display: -webkit-flex;
				-webkit-justify-content: center;
				-webkit-align-items: center;
			}

			${props =>
				props.$gridCount <= 2 &&
				css`
					width: calc((16 / 9) * (100vh - 65px)) !important;
					max-height: calc(100vh - 65px) !important;
				`};

			${props =>
				props.$isMultiBed &&
				css`
					@media (max-width: ${WindowSize.TABLET}px) {
						max-width: unset;
						width: 166%;
					}
				`}
		}

		${props =>
			(!props.$isViewPatient || props.$gridCount === 2) &&
			(props.$isInviteParticipantsModalViewOpen ||
				props.$isLiveCaptionsOpen ||
				props.$isMedicalInfoOpen ||
				props.$isPatientMeasurementsOpen ||
				props.$isMoreParticipantsViewOpen ||
				props.$isWhiteboardVisible ||
				props.$isAiOpen ||
				props.$isRoomSignOpen) &&
			css`
				@media (min-width: ${WindowSize.TABLET}px) {
					align-items: center;
					justify-content: center;
					margin: 0;
					> main {
						width: calc(16 / 9 * 100dvh);
						max-width: ${props.$isPatientMeasurementsOpen ? 50 : 70}dvw;
						height: calc(9 / 16 * ${props.$isPatientMeasurementsOpen ? 50 : 70}dvw);
						max-height: 100dvh;

						${props.$isInviteParticipantsModalViewOpen &&
						props.$isPatientMeasurementsOpen &&
						css`
							max-width: 70dvw;
							height: calc(9 / 16 * 70dvw);
						`}

						${!props.$isViewPatient &&
						css`
							aside {
								> header,
								> aside {
									transform: scale(0.8);
									transform-origin: top left;
								}
								aside:first-of-type {
									margin-top: -5px;
								}
								aside:nth-of-type(2) {
									margin-top: -20px;
								}
							}
						`}
					}
				}
			`};

		${props =>
			props.$isViewPatient &&
			css`
				> main {
					width: calc(16 / 9 * (100vh - 60px));
					max-width: 100vw;
					height: calc(9 / 16 * 100vw);
					max-height: calc(100vh - 60px);
					margin: auto;
				}
				${(props.$isMedicalInfoOpen || props.$isWhiteboardVisible || props.$isAiOpen || props.$isRoomSignOpen) &&
				css`
					margin: 0;
					> main {
						max-width: 70vw;
						height: calc(9 / 16 * 70vw);
					}
				`}
			`};

		${props =>
			props.$isGridView &&
			!props.$isViewPatient &&
			css`
				> main {
					display: none;
					&:not(:has(> video)) {
						display: none;
					}
				}
			`};

		~ .patient-chat-grid {
			position: absolute;
			top: ${LightTheme.spacing[5]}px;
			right: ${LightTheme.spacing[5]}px;
			z-index: 10;
			width: 280px;
			.patient-chat {
				height: calc(100% - 120px);
				border-radius: ${LightTheme.borderRadius.base}px;
				overflow: hidden;

				.patient-chat-area {
					border-radius: 0;
				}
			}

			.circle-loader {
				position: static;
			}

			.gif-loading {
				transform: none;
				position: relative;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				left: 0;
				top: 0;
			}
		}
	}
	@media (max-width: ${WindowSize.TABLET}px) {
		height: 100%;
		padding-bottom: 0;

		.participants-wrapper {
			width: 100%;
			height: calc(100% - 100px);
			overflow: auto;

			> main {
				width: 100%;
				height: auto;
				aspect-ratio: 16 / 9;
			}
		}
	}
`;

const GlobalStyle = createGlobalStyle`
	html,
	body {
		margin: 0;
		padding: 0;
		height: 100%;
	}

	#root {
		display: flex;
		height: 100%;
	}

	@media (max-width: ${WindowSize.TABLET}px) {
		html,
		body,
		#root {
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			user-select: none;
		}
	}

	video::-webkit-media-controls {
		display: none;
	}

	aside.right-side,
	aside.right-side.live-examination-kit,
	aside.right-side.medical-info-aside,
	.new-experience .right-side-monitoring.rounding-right-aside {
		height: calc(100vh - 60px) !important;
		@media (max-width: ${WindowSize.TABLET}px) {
			height: calc(100dvh - 120px) !important;
		}
	}

	@media (min-width: ${WindowSize.TABLET}px) {
		body:hover {
			#otoscopeNotification {
				transform: translateY(0);
			}
		}
	}
	
	
	.monitoring-vital-signs-ai-wrapper.row {
		.vital-signs-ai {
			&.rpm-measurements-ttp {
		      bottom: 70px;
	        }
	    }
    } 
`;

/**
 * @param {object} props
 * @param {object} props.participantsToCall Participants to call on conference start
 * @param {boolean} [props.isJoin=false]
 * @param {string} [props.refToken='']
 */
const ConferenceCall = ({ participantsToCall = [], isJoin = false, refToken = '' }) => {
	const conference = useConference();
	const conferenceParticipants = useConferenceParticipants();
	const conferenceConfigs = useConferenceConfigurations();
	const conferenceState = useConferenceState();
	const localParticipant = useLocalParticipant();
	const screenType = useScreenType();
	const location = useLocation();
	const socket = useContext(SocketContext);
	const [busyVisitorParticipant, setBusyVisitorParticipant] = useState(null);
	const [isPatientView] = useState(conference.callType === enums.CallTypes.SECURITYCAM);

	/**
	 * @typedef {import('calls/LocalParticipant.js').default | import('calls/RemoteParticipant.js').default} Participant
	 * @type {[Participant, import('react').Dispatch<import('react').SetStateAction<Participant>>]}
	 */
	const [mainParticipant, setMainParticipant] = useState(conference.localParticipant);
	const [canScreenShare, setCanScreenShare] = useState(null);
	const [activeTrackType, setActiveTrackType] = useState(Cam);
	const intl = useIntl();
	const [isCursorStill] = useState(false);
	const [mrnDifferenceMessage, setMrnDifferenceMessage] = useState(null);
	const [showCallControlsOnMobile, setShowCallControlsOnMobile] = useState(false);
	const [failedInvites, setFailedInvites] = useState([]);
	const [failedInviteMessage, setFailedInviteMessage] = useState(translate('failedToGetParticipantInfo'));
	const [trasnferredOwnerMessage, setTransferredOwnerMessage] = useState('');
	const locale = useSelector(state => state.language.locale);
	const darkMode = useSelector(state => state.user.darkMode);
	const helloName = useSelector(state => state.company.companySettings.helloName);
	const userSession = useSelector(state => state.user.userSession);
	const healthSystems = useSelector(state => state.healthSystems);
	const mainParticipantVideoRef = useRef(null);
	const mainViewRef = useRef(null);
	const afterConferenceEndedTimeoutRef = useRef(null);
	const { roundingConfigurations } = conferenceConfigs;
	const isPrimaryCareType = useRef(null);
	const closeTabTimeout = useRef(null);
	const transferredOwnerMessageTimeout = useRef(null);
	const visualSettings = useSelector(state => state.configurations.unboundHealthSystemSettings.visualsSettings);
	const isNewExperience = useSelector(state => state.configurations.isNewExperience);
	const userSettings = useSelector(state => state.configurations.userSettings);
	const roleRoundingConfigurations = useSelector(state => state.configurations.roleRoundingConfigurations);
	const helloParticipant = findRemoteHelloParticipant(conference.participants);

	let micPermissionPromiseResolveCallback = null;
	const micPermissionPromise = new Promise(resolve => {
		micPermissionPromiseResolveCallback = resolve;
	});

	const history = useHistory();

	const dispatch = useDispatch();

	const micStatus = useRef(null);

	const WindowMessages = {
		Window: {
			CLOSE: 'window.close',
			MAXIMIZE: 'window.maximize',
			MINIMIZE: 'window.minimize',
		},
		Call: {
			STARTED: 'call.started',
			ENDED: 'call.ended',
		},
	};

	const canLeaveConference = conferenceState instanceof Conference.StateInitiated;
	const transferrableParticipants = conferenceParticipants.filter(
		participant =>
			!(participant instanceof RemoteHelloParticipant) &&
			[UserTypes.NURSE, UserTypes.DOCTOR].includes(participant.role) &&
			participant.objectType !== ObjectType.SIP_USER
	);

	useEffect(() => {
		const foundHs =
			healthSystems.allHealthSystems.find(item => item.id === userSession.healthSystem.id)?.workflowTypeId ===
			HealthSystemType.PRIMARY_CARE;
		isPrimaryCareType.current = foundHs;
	}, [healthSystems, userSession]);

	useEffect(() => {
		conferenceConfigs.onConfigurationToggleAction(ControlsActions.IS_RIGHT_TO_LEFT, locale === LOCALES.ARABIC);
		conferenceConfigs.onConfigurationToggleAction(ControlsActions.SET_HELLO_NAME, helloName);
		if ([UserRoles.NURSE, UserRoles.DOCTOR].includes(getUserRole())) {
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.IS_DARK_MODE, darkMode);
		}
		const queryParams = new URLSearchParams(location.search);
		const roomType = queryParams.get(StartQueryStringKeys.ROOM_TYPE);
		const isMultiBedQueryParam = queryParams.get(StartQueryStringKeys.IS_MULTI_BED);

		conferenceConfigs.setIsFitScreen(+roomType === RoomTypes.BABY_ROOM.type);
		if (isMultiBedQueryParam === 'true') {
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.IS_MULTI_BED);
		}

		const bindMediaEvents = async () => {
			micStatus.current = await checkForPermission(MediaTypes.MICROPHONE);
			micStatus.current.onchange = onDevicePermissionChange;
		};
		bindMediaEvents();
	}, []);

	const onDevicePermissionChange = res => {
		if (res.target.state === MediaPermissions.GRANTED || res.target.state === MediaPermissions.PROMPT) {
			dispatch(healthSystemsActionCreators.setStreamPermissionMessage(null));
			if (res.target.state === MediaPermissions.GRANTED) {
				micPermissionPromiseResolveCallback();
			}
		}
	};

	useEffect(() => {
		let trackConfig = { substream: 0, temporal: 0 }; // LowResLowFps
		const participantsSize = conferenceParticipants.length;
		if (participantsSize > 9 && conferenceConfigs.isGridView) {
			trackConfig = { substream: 0, temporal: 1 }; // LowResHighFps
		} else if (participantsSize > 4 && conferenceConfigs.isGridView) {
			trackConfig = { substream: 1, temporal: 1 }; // MidResHighFps
		} else if (conferenceConfigs.isGridView) {
			trackConfig = { substream: 2, temporal: 1 }; // HighResHighFps
		}

		const config = {};
		conferenceParticipants.forEach(participant => {
			config[participant.id] = {
				[Cam]:
					!conferenceConfigs.isGridView && participant.id === mainParticipant?.id ? { substream: 2, temporal: 1 } : trackConfig, // HighResHighFps
			};
		});

		conference.config(config);
	}, [conference, conferenceParticipants, mainParticipant, conferenceConfigs.isGridView]);

	useEffect(() => {
		const onLocalTrackChanged = trackEvent => {
			if (trackEvent instanceof LocalTrackAdded) {
				if (trackEvent.track.type === ScreenShare) {
					conferenceConfigs.setGridCount(prevState => prevState + 1);
				}

				if (trackEvent.track.type === Mic) {
					const existingLocalParticipant = conferenceConfigs.speechToTextParticipants.find(
						participant => participant.id === localParticipant.id
					);
					if (!existingLocalParticipant) {
						conferenceConfigs.pushSpeechToTextParticipant(localParticipant);
					}
				}
			}

			if (trackEvent instanceof LocalTrackRemoved) {
				if (trackEvent.type === ScreenShare) {
					if (localParticipant === mainParticipant) {
						setActiveTrackType(Cam);
					}
					conferenceConfigs.setGridCount(prevState => prevState - 1);
				}

				if (trackEvent.type === Mic) {
					conferenceConfigs.removeSpeechToTextParticipant(localParticipant);
				}
			}
		};

		return localParticipant.localTrackController.on(onLocalTrackChanged);
	}, [conferenceConfigs, localParticipant, mainParticipant]);

	const leaveConference = async (evt, shouldEndCall = false) => {
		if (!canLeaveConference) {
			conferenceConfigs.setIsTransferOwnershipModalopen(false);
			return;
		}
		evt.stopPropagation();
		postEndMessage();
		if (transferrableParticipants.length > 0 && localParticipant.isOwner && !shouldEndCall) {
			const transferred = await conference.transferOwnership(transferrableParticipants[0].id);
			if (transferred) {
				conferenceConfigs.setIsTransferOwnershipModalopen(false);
			}
		}
		conference.leave();
		conference.close(enums.ConferenceEndReasons.PARTICIPANT_LEFT);
	};

	const startConference = async () => {
		if (!(conference.state instanceof Conference.StateInitialized)) {
			return;
		}

		await conference.socket.authPromise;

		if (!localParticipant.isGuest) {
			// When participant is not a guest at talk to patient, at least microphone permissions should be allowed
			const { microphone } = await checkIfMediaDevicesPlugged();
			if (!microphone) {
				dispatch(
					healthSystemsActionCreators.setStreamPermissionMessage({
						component: ErrorComponentTypes.Modal,
						type: StreamError.MICROPHONE_NOT_FOUND.type,
					})
				);
				return;
			}

			if (localParticipant.requestedLocalTracks.includes(Mic)) {
				try {
					await localParticipant.localTrackController.add(Mic);
				} catch (error) {
					if (error?.error?.name === UserPermissionDeniedErrors.NotAllowedError) {
						dispatch(
							healthSystemsActionCreators.setStreamPermissionMessage({
								component: isChrome || isEdgeChromium ? ErrorComponentTypes.Popup : ErrorComponentTypes.Modal,
								type:
									isChrome || isEdgeChromium ? StreamError.MICROPHONE_BLOCKED.type : StreamError.MICROPHONE_BLOCKED_GENERIC.type,
							})
						);
						await micPermissionPromise;
					} else {
						conferenceConfigs.setConferenceErrorMessages([{ id: uuidv4(), message: translate('somethingWentWrong') }]);
					}
				}
			}

			if (localParticipant.requestedLocalTracks.includes(Cam)) {
				try {
					const params = await getUserBackgroundParams({
						localParticipant,
						userSettings,
						visualSettings,
						healthSystemId: userSession.healthSystem.id,
					});
					await localParticipant.localTrackController.add(Cam);
					if (localParticipant.localTrackController.tracks[Cam]) {
						changeLocalParticipantBackground(params);
					}
					// eslint-disable-next-line no-empty
				} catch (error) {
					conferenceConfigs.setConferenceErrorMessages([{ id: uuidv4(), message: translate('somethingWentWrong') }]);
				}
			}
		} else {
			try {
				const params = await getUserBackgroundParams({
					localParticipant,
					userSettings,
					visualSettings,
					healthSystemId: userSession.healthSystem.id,
				});
				await localParticipant.localTrackController.add(localParticipant.requestedLocalTracks);
				if (localParticipant.localTrackController.tracks[Cam]) {
					changeLocalParticipantBackground(params);
				}
			} catch (error) {
				conferenceConfigs.setConferenceErrorMessages([{ id: uuidv4(), message: translate('somethingWentWrong') }]);
			}
		}

		localParticipant.remoteTrackController.request(callTypeToTrackTypes(conference.callType).remoteTrackTypes);
		let isSuccessful;
		if (isJoin) {
			const response = await conference.join(refToken);
			if (response.data?.initiator?.integrationId === IframeIntregrationsIds.PHILIPS && location.state) {
				location.state.integrationId = response.data.initiator.integrationId;
			}
			if (response.data.failureReason === JoinConferenceFailureReasonEnum.FAILED_TO_GET_PARTICIPANT_INFO) {
				setFailedInviteMessage(translate('failedToGetYourInfo'));
			}
			if (response.data.failureReason === JoinConferenceFailureReasonEnum.NULL_CONFERENCE && clearGuestUserCheck(true)) {
				conference.socket.doDisconnect();
			}
			isSuccessful = response.ok;
		} else {
			const response = await conference.start(participantsToCall, false, refToken);
			isSuccessful = response.ok;
			if (response.data?.failedInvitationToParticipants?.length) {
				conferenceConfigs.setConferenceErrorMessages(
					response.data.failedInvitationToParticipants.map(participant => ({
						id: participant.id,
						message: getFailedInvitationMessage(participant),
					}))
				);
			}
			window.postMessage(isSuccessful ? 'conference.hasStarted' : 'conference.hasFailed', APP_CONFIG.URL.localApiBasePath);
			window.parent.postMessage(WindowMessages.Call.STARTED, '*');
		}
		if (isSuccessful) {
			conference.logger.debug(`Conference ${isJoin ? 'join()' : 'start()'} successful.`);
		}
	};

	const postEndMessage = () => {
		if (window.parent && conference) {
			window.parent.postMessage(
				JSON.stringify({
					event: WindowMessages.Call.ENDED,
					callType: conference.callType === CallTypes.SECURITY_CAM ? 'view patient' : 'talk to patient',
					roomSid: conference.conferenceId,
					participantId: localParticipant.id,
					participantSid: localParticipant.id,
				}),
				'*'
			);
		}
	};

	const setPatientConferenceInfo = obj => {
		conference.updateAdditionalData([
			...(conference.additionalData || []),
			{
				key: AdditionalData.PATIENT_INFO,
				value: obj,
			},
		]);
	};

	const fetchRoundingData = async deviceId => {
		const deviceOwnerResponse = await getDeviceOwnerPatient(deviceId);
		const foundSector = findSectorById(healthSystems.treeData.tree, deviceId);
		if (deviceOwnerResponse && !deviceOwnerResponse.error) {
			setPatientConferenceInfo({
				patientName: deviceOwnerResponse.isVirtualPatient ? '' : deviceOwnerResponse.fullName,
				room: foundSector?.name ?? '',
				...(deviceOwnerResponse.mrn && { mrn: deviceOwnerResponse.mrn }),
				...(deviceOwnerResponse.birthDate && { dateOfBirth: getMonthDayYearDateFormat(deviceOwnerResponse.birthDate) }),
			});
			conferenceConfigs.setPatientInfo(deviceOwnerResponse);
			return;
		}
		setPatientConferenceInfo({ room: foundSector?.name ?? '' });
	};

	const fetchEpicConverge = async () => {
		const response = await getCurrentSessionPatientData();
		if (!response.error) {
			setPatientConferenceInfo({
				patientName: `${response.firstName} ${response.lastName}`,
				mrn: response.mrn,
				dateOfBirth: response.dateOfBirth,
			});
		}
	};

	const buildFullName = patient => {
		if (!patient) return '';
		const fullName = [patient.firstName, patient.lastName].filter(Boolean).join(' ');
		return fullName || '';
	};

	const fetchPhilipsData = async deviceId => {
		const [roomInfoResponse, mrnResponse] = await Promise.all([
			getRoomInfo(conference.conferenceId, refToken),
			getOwnerMrn(deviceId),
		]);
		if (roomInfoResponse.error) {
			return;
		}
		if (mrnResponse.error) {
			mrnResponse.mrn = '';
		}
		const metadata = JSON.parse(roomInfoResponse.metadata);

		const getMrnMessage = (mrn1, mrn2) => (mrn1 && mrn2 && mrn1 !== mrn2 ? translate('mrnNoMatch') : '');

		setPatientConferenceInfo({
			patientName: buildFullName(metadata?.patient),
			mrn: metadata?.patient?.mrn,
			dateOfBirth: metadata?.patient?.dateOfBirth,
			bedIdentifier: metadata?.bedIdentifier,
		});
		setMrnDifferenceMessage(getMrnMessage(metadata?.patient?.mrn, mrnResponse.mrn)); // TODO: Set this variable in patient info conference
	};

	const fetchAssignedReqId = async id => {
		const response = await getRequest(id);
		if (response.error) {
			return;
		}
		const request = response.assignedRequest.request;
		conference.updateAdditionalData([
			...(conference.additionalData || []),
			{
				key: AdditionalData.PATIENT_INFO,
				value: {
					mrn: request?.patientExtendedProfile?.mrn,
					reasonToConnect: request?.requestReason
						? intl.formatMessage({ id: getDescFromEnum(request?.requestReason, VisitReasons) })
						: '',
					name: request?.requestIntakeInfo?.nurseFullName,
					phone: request?.requestIntakeInfo?.nurseStationPhone,
					ext: request?.requestIntakeInfo?.nurseExt,
				},
			},
		]);
		// setPatientConferenceInfo(obj);
	};

	const fetchPatientData = async participant => {
		if (!participant?.objectId || participant?.role !== UserTypes.PATIENT) {
			return;
		}
		const result = await getPatientByUserId(participant.objectId);
		if (!result.error) {
			const patientProfile = result?.patient?.profile;
			setPatientConferenceInfo({
				patientName: buildFullName(patientProfile),
				mrn: patientProfile?.mrn,
				...(patientProfile.birthDate && { dateOfBirth: getMonthDayYearDateFormat(patientProfile.birthDate) }),
			});
		}
	};

	const fetchPatientInfo = async deviceId => {
		const queryParams = new URLSearchParams(location.search);
		const assignedRequestId = queryParams.get('assignedRequestId');
		if (assignedRequestId) {
			fetchAssignedReqId(assignedRequestId);
			return;
		}

		const isEpicOrConverge = isSessionEhr() && !isSmartLauncher();
		if (isEpicOrConverge) {
			fetchEpicConverge();
			return;
		}

		if (!refToken && ![UserRoles.VISITOR, UserRoles.GUEST].includes(getUserRole())) {
			fetchRoundingData(deviceId);
			return;
		}

		if (!refToken) {
			return;
		}
		fetchPhilipsData(deviceId);
	};

	useEffect(() => {
		startConference();
		// Disable reason: useEffect should be invoked once
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (failedInvites) {
			conferenceConfigs.setConferenceErrorMessages(
				failedInvites.map(failedParticipant => ({
					id: failedParticipant.userId,
					message: getFailedInvitationMessage(failedParticipant),
				}))
			);
		}
	}, [failedInvites]);

	useEffect(() => {
		const beforeUnloadEvent = event => {
			if (!(conference.state instanceof Conference.StateEnded)) {
				event.preventDefault();
				// eslint-disable-next-line no-param-reassign
				event.returnValue = '';
			}
		};

		const onMessage = async ({ origin, data }) => {
			// remove last ending slash
			if (origin.replace(/\/$/, '') !== APP_CONFIG.URL.localApiBasePath.replace(/\/$/, '')) {
				return;
			}
			if (data && typeof data === 'object' && data?.messageType === 'doctorRequestData') {
				const doctorRequestData = data.requestData;
				doctorRequestData.conferenceId = conference.conferenceId;
				const response = await sendDoctorRequest(doctorRequestData, getCompanyId());
				if (response.error) {
					window.postMessage('create.request.hasFailed', APP_CONFIG.URL.localApiBasePath);
					conference.leave();
					conference.close(enums.ConferenceEndReasons.PARTICIPANT_LEFT);
				}
			}
			if (data === 'IN_CALL') {
				window.removeEventListener('beforeunload', beforeUnloadEvent);

				conference.leave();
				if (clearGuestUserCheck()) {
					conference.socket.doDisconnect();
				}
				conference.close(enums.ConferenceEndReasons.PARTICIPANT_IDLE);
			}
		};

		const leaveOnUnload = () => {
			if (!(conference.state instanceof Conference.StateEnded)) {
				conference.leave();
			}
			if (clearGuestUserCheck()) {
				conference.socket.doDisconnect();
			}
		};

		window.addEventListener('beforeunload', beforeUnloadEvent);
		window.addEventListener('message', onMessage);
		window.addEventListener('unload', leaveOnUnload);

		return () => {
			leaveOnUnload();
			stopOutgoingCallSound();

			if (afterConferenceEndedTimeoutRef.current) {
				clearTimeout(afterConferenceEndedTimeoutRef.current);
			}

			if (closeTabTimeout.current) {
				clearTimeout(closeTabTimeout.current);
			}

			if (transferredOwnerMessageTimeout.current) {
				clearTimeout(transferredOwnerMessageTimeout.current);
			}

			window.removeEventListener('beforeunload', beforeUnloadEvent);
			window.removeEventListener('message', onMessage);
			window.removeEventListener('unload', leaveOnUnload);
		};
	}, [conference]);

	useEffect(() => {
		return conference.on(event => {
			const participantListeners = new Map();
			const helloParticipant = findRemoteHelloParticipant(conference.participants);
			const patientParticipant = findPatientRoleParticipant(conference.participants);
			const mainScreenParticipant = helloParticipant || patientParticipant;

			if (event instanceof Conference.ParticipantAdded) {
				conferenceConfigs.setGridCount(prevState => prevState + 1);

				if (
					conference.participants.size > 1 &&
					mainScreenParticipant &&
					!conferenceConfigs.pinnedParticipantId &&
					activeTrackType !== ScreenShare
				) {
					setMainParticipant(mainScreenParticipant);
					setActiveTrackType(Cam);
				}
				const onParticipantStateChanged = state => {
					if (state instanceof Participant.StateConnected) {
						if (conference.participants.size === 1) {
							stopOutgoingCallSound();
							setMainParticipant(event.participant);
							setActiveTrackType(Cam);
						}
						if (helloParticipant) {
							fetchPatientInfo(helloParticipant.objectId);
						} else {
							fetchPatientData(event.participant);
						}
						if (conference.participants.size > 1 && mainScreenParticipant && canScreenShare) {
							stopOutgoingCallSound();
							setMainParticipant(mainScreenParticipant);
							setActiveTrackType(Cam);
						}
					}

					if (state instanceof Participant.HelloDeviceStateChanged) {
						const { isCameraPrivacyOn } = event.participant.deviceState;
						if (getUserRole() === UserRoles.VISITOR && isCameraPrivacyOn) {
							// familymember
							conference.leave();
							let endReason;
							if (state instanceof Participant.MicPrivacyStateChanged || state instanceof Participant.CameraPrivacyStateChanged) {
								endReason = enums.ConferenceEndReasons.TERMINATED_BY_ADMINISTRATOR;
							} else {
								endReason = enums.ConferenceEndReasons.PARTICIPANT_DECLINED;
							}
							conference.close(endReason);
						}
					}
				};

				const onRemoteTrackChanged = trackEvent => {
					const sortedParticipants = [localParticipant, ...conference.participants.values()]
						.filter(p => !(p instanceof RemoteHelloParticipant))
						.sort((a, b) => a.objectId - b.objectId);

					if (trackEvent instanceof RemoteTrackAdded) {
						if (trackEvent.track.type === ScreenShare) {
							conferenceConfigs.setGridCount(prevState => prevState + 1);
						}
						if (trackEvent.track.type !== Mic && !(event.participant instanceof RemoteHelloParticipant)) {
							if (trackEvent.track.type === ScreenShare && !conferenceConfigs.pinnedParticipantIdRef.current) {
								setMainParticipant(event.participant);
								setActiveTrackType(ScreenShare);
							}

							if (trackEvent.track.type === Cam && activeTrackType === ScreenShare) {
								setActiveTrackType(ScreenShare);
							}
						}
						if (
							localParticipant.localTrackController.tracks[ScreenShare] &&
							trackEvent.track.type === ScreenShare &&
							!(event.participant instanceof RemoteHelloParticipant)
						) {
							sortedParticipants.forEach(p => {
								if (localParticipant.id !== sortedParticipants[0].id) {
									setCanScreenShare({
										...canScreenShare,
										[p.id]: false,
									});
									p.localTrackController.remove(ScreenShare);
								}
							});
						}
						if (trackEvent.track.type === Mic) {
							const existingRemoteParticipant = conferenceConfigs.speechToTextParticipants.find(
								participant => participant.id === event.participant.id
							);
							if (!existingRemoteParticipant) {
								conferenceConfigs.pushSpeechToTextParticipant(event.participant);
							}
						}
					}
					if (trackEvent instanceof RemoteTrackRemoved) {
						if (trackEvent.type === ScreenShare) {
							if (!conferenceConfigs.pinnedParticipantIdRef.current) {
								if (mainScreenParticipant) {
									setMainParticipant(mainScreenParticipant);
								}
								setActiveTrackType(Cam);
							}
							conferenceConfigs.setGridCount(prevState => prevState - 1);
						}
						if (trackEvent.type === Mic) {
							conferenceConfigs.removeSpeechToTextParticipant(event.participant);
						}
					}
				};
				const unbindParticipantListeners = event.participant.on(onParticipantStateChanged);
				const unbindParticipantTrackListeners = event.participant.remoteTrackController.on(onRemoteTrackChanged);
				participantListeners.set(event.participant, () => {
					unbindParticipantListeners();
					unbindParticipantTrackListeners();
				});
			} else if (event instanceof Conference.ParticipantRemoved) {
				conferenceConfigs.setGridCount(prevState => prevState - 1);
				const { participant } = event;
				if (!(event.participant instanceof RemoteHelloParticipant) || event.participant.objectType !== ObjectType.SIP_USER) {
					transferrableParticipants.filter(p => p.id !== participant.id);
				}
				setMainParticipant(prevState => {
					if (participant !== prevState) {
						return prevState;
					}

					setActiveTrackType(Cam);
					if (conference.participants.size > 0) {
						return [...conference.participants.values()].reverse()[0];
					}

					return conference.localParticipant;
				});

				if (participantListeners.has(participant)) {
					participantListeners.get(participant)();
				}
			}
		});
	}, [conference, conferenceConfigs, localParticipant, activeTrackType, canScreenShare]);

	useEffect(() => {
		return conference.on(event => {
			const queryParams = new URLSearchParams(location.search);
			const roomType = queryParams.get(StartQueryStringKeys.ROOM_TYPE);
			if (event instanceof Conference.StateInitiating && !isJoin && !conference.isMultiparty && !conference.isMeetingRoom) {
				outGoingCallSound();
			} else if (event instanceof Conference.ParticipantAdded && screenType.isSmall && conferenceConfigs.gridCount > 2) {
				conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_GRID_VIEW, true);
			} else if (event instanceof Conference.StateEnded) {
				postEndMessage();
				if (
					event.activeConferences &&
					event.endReason === enums.ConferenceEndReasons.PARTICIPANT_BUSY &&
					+roomType === RoomTypes.BABY_ROOM.type
				) {
					setBusyVisitorParticipant(event.activeConferences[0].participants[0]);
				}
				const playDropSound = async () => {
					stopOutgoingCallSound();
					await dropSound();
				};
				if (event.endReason === enums.ConferenceEndReasons.TERMINATED_BY_ADMINISTRATOR) {
					playDropSound();
				} else if (!event.endReason || event.endReason === enums.ConferenceEndReasons.PARTICIPANT_LEFT) {
					stopOutgoingCallSound();
				}

				const shouldDisconnectSocket = clearGuestUserCheck(event.endReason !== enums.ConferenceEndReasons.PARTICIPANT_LEFT);
				if (shouldDisconnectSocket) {
					conference.socket.doDisconnect();
				}
			}
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [conference, location.search, isJoin]);

	useEffect(() => {
		if (conferenceState instanceof Conference.StateEnded) {
			afterConferenceEndedTimeoutRef.current = setTimeout(async () => {
				if (getUserRole() === UserRoles.VISITOR && isMobileSafari) {
					history.push('/devices');
					return;
				}

				if (isSessionEhr()) {
					history.push('/call-patient-options');
					return;
				}

				if (
					!conferenceConfigs.visitId &&
					!location.state &&
					(!getRoleConfigurationValue(roleRoundingConfigurations, RoundingSettings.RateCall) ||
						deviceUnavailable(conferenceState.endReason))
				) {
					window.close();
				}

				if (conferenceConfigs.visitId && !getRoleConfigurationValue(roleRoundingConfigurations, RoundingSettings.SessionWrapUp)) {
					await submitVisit();
					if (
						getRoleConfigurationValue(roleRoundingConfigurations, RoundingSettings.RateCall) &&
						!deviceUnavailable(conferenceState.endReason)
					) {
						return;
					}
				}

				window.parent.postMessage(WindowMessages.Window.CLOSE, '*');
			}, getConferenceEndedTimeout());
		}
	}, [conferenceState]);

	useEffect(() => {
		return conference.on(event => {
			if (event instanceof Conference.StateEnded) {
				const playConferenceEndedSound = async () => {
					switch (event.endReason) {
						case enums.ConferenceEndReasons.PARTICIPANT_OFFLINE:
						case enums.ConferenceEndReasons.PARTICIPANT_BUSY:
						case enums.ConferenceEndReasons.PARTICIPANT_NOT_ANSWERING:
						case enums.ConferenceEndReasons.PARTICIPANT_DECLINED:
						case enums.ConferenceEndReasons.HAS_ACTIVE_CONFERENCE:
						case enums.ConferenceEndReasons.FAILED_TO_GET_INITIATOR_INFO:
						case enums.ConferenceEndReasons.PARTICIPANT_INVITE_FAILED:
						case enums.ConferenceEndReasons.PARTICIPANT_INVITE_DENIED:
							stopOutgoingCallSound();
							await busySound();
							break;
						case enums.ConferenceEndReasons.OWNER_LEFT:
						case enums.ConferenceEndReasons.TERMINATED_BY_ADMINISTRATOR:
							stopOutgoingCallSound();
							await dropSound();
							break;
						default:
							stopOutgoingCallSound();
							break;
					}
				};
				playConferenceEndedSound();
			}
		});
	}, [conference]);

	useEffect(() => {
		const canFetchRoomConfigs = state => state instanceof Participant.StateConnected && conference.participants.size === 1;

		return conference.on(event => {
			if (event instanceof Conference.ParticipantAdded) {
				const onParticipantStateChanged = state => {
					if (canFetchRoomConfigs(state)) {
						conferenceConfigs.onSetCategoryConfigurationsAction(
							ControlsActions.SET_ROUNDING_CONFIGURATIONS,
							roleRoundingConfigurations
						);
					}
				};

				event.participant.on(onParticipantStateChanged);
			}
			if (event instanceof Conference.OwnerChanged && event.participant.id === localParticipant.id) {
				localParticipant.isGuest = false;
				setTransferredOwnerMessage(intl.formatMessage({ id: 'transferOwnershipMessage' }));
				conferenceConfigs.onConfigurationToggleAction(ControlsActions.SET_SHOW_PATIENT_MEASUREMENTS_BUTTONS, true);
				conferenceConfigs.onSetCategoryConfigurationsAction(
					ControlsActions.SET_ROUNDING_CONFIGURATIONS,
					roleRoundingConfigurations
				);
				transferredOwnerMessageTimeout.current = setTimeout(() => {
					setTransferredOwnerMessage('');
				}, 3000);
			}
		});
	}, [conference, conferenceConfigs, healthSystems.treeData.tree, localParticipant, roleRoundingConfigurations]);

	const clearGuestUserCheck = (shouldClearSession = false) => {
		const isGuestUserRole = getUserRoleId() === UserTypes.GUEST && shouldClearSession;
		if (isGuestUserRole) {
			conference.collector.close(sessionStorage.getItem('access_token'));
			sessionStorage.clear();
			localStorage.clear();
		}

		return isGuestUserRole;
	};

	const onControlsAction = actionType => {
		switch (actionType) {
			case ControlsActions.MAIN_PARTICIPANT_SCREEN_CAPTURE:
				captureFrame(mainViewRef.current);
				break;
			default:
				break;
		}
	};

	const submitVisit = async visitStatus => {
		const currentParams = queryString.parse(history.location.search);
		if (currentParams.appointmentId) {
			await updateAppointmentStatus(currentParams.appointmentId, AppointmentStatus.COMPLETED);
		}

		if (isPrimaryCareType.current) {
			await updateMedicalVisitStatus(currentParams.medicalVisitId, { visitStatus });
		} else {
			await saveVisitEndDateTime(conferenceConfigs.visitId);
		}
		startCloseTabTimeout();
	};

	const startCloseTabTimeout = () => {
		conferenceConfigs.setVisitId(null);
		closeTabTimeout.current = setTimeout(() => {
			window.parent.postMessage(WindowMessages.Window.CLOSE, '*');
			window.close();
		}, getConferenceEndedTimeout());
	};

	const showMessageOnSubmitWrapUp = () =>
		!getRoleConfigurationValue(roundingConfigurations, RoundingSettings.SessionWrapUp) &&
		conferenceConfigs.visitId &&
		conferenceState instanceof Conference.StateEnded;

	const getParticipantToDisplay = () => {
		if (conferenceConfigs.isLiveExaminationOpen || conferenceConfigs.isInviteParticipantsModalViewOpen) {
			return helloParticipant;
		}

		if (localParticipant !== mainParticipant && !conferenceConfigs.isGridView) {
			return localParticipant;
		}

		return null;
	};

	return (
		<div
			className={classNames(
				'full-width full-height overflow-hidden',
				isNewExperience ? 'new-experience' : 'old-experience',
				conferenceConfigs.isLiveExaminationOpen ? 'live-examination-wrapper' : ''
			)}>
			{conferenceConfigs.isMediaBlockedPopupOpen && <MediaBlockedPopup />}
			{((conferenceState instanceof Conference.StateEnded && !conferenceConfigs.visitId) || showMessageOnSubmitWrapUp()) && (
				<ConferenceEndedMessage
					refToken={refToken}
					busyVisitorParticipant={busyVisitorParticipant}
					failedInviteMessage={failedInviteMessage}
				/>
			)}
			{!(conferenceState instanceof Conference.StateEnded) && (
				<>
					<GlobalStyle />
					<StyledConference
						className={classNames('call-wrapper', conferenceConfigs.isRightToLeft ? 'direction-rtl-wrapper' : '')}
						ref={mainViewRef}
						$isCursorStill={isCursorStill}
						$isDarkMode={conferenceConfigs.isDarkMode}
						$isViewPatient={isPatientView}
						$isGridView={conferenceConfigs.isGridView}
						$gridCount={conferenceConfigs.gridCount}
						$isPatientMeasurementsOpen={
							conferenceConfigs.isLiveExaminationOpen ||
							conferenceConfigs.isPatientHistoryOpen ||
							conferenceConfigs.isTelemetryModalOpen
						}
						$isLiveCaptionsOpen={conferenceConfigs.isLiveCaptionsOpen}
						$isMedicalInfoOpen={isMedicalInfoModalsOpen(conferenceConfigs.medicalDataControls)}
						$isInviteParticipantsModalViewOpen={conferenceConfigs.isInviteParticipantsModalViewOpen}
						$isMoreParticipantsViewOpen={conferenceConfigs.isMoreParticipantsViewOpen}
						$isMultiBed={conferenceConfigs.isMultiBed}
						$isWhiteboardVisible={conferenceConfigs.isWhiteboardVisible}
						$isAiOpen={conferenceConfigs.isAiOpen}
						$isRoomSignOpen={conferenceConfigs.isRoomSignOpen}
						onClick={() => setShowCallControlsOnMobile(prevState => !prevState)}>
						<div className='participants-wrapper'>
							{!isPatientView && (
								<>
									<ParticipantsView
										mainParticipant={mainParticipant}
										activeTrackType={activeTrackType}
										setMainParticipant={participant => setMainParticipant(participant)}
										setActiveTrackType={setActiveTrackType}
									/>
									{getUserRoleId() !== UserTypes.GUEST &&
										mainParticipant instanceof RemoteParticipant &&
										mainParticipant.clientType !== enums.ClientTypes.WEB && <CameraMeasurements patient={mainParticipant} />}
									{conferenceConfigs.isInviteParticipantsModalViewOpen && (
										<InviteParticipantsView
											position='right'
											roomId={
												mainParticipant.objectType === enums.ObjectTypes.HELLO_DEVICE
													? findSectorById(healthSystems.treeData.tree, mainParticipant.objectId)?.roomId
													: null
											}
											configurations={{
												isMeetingURLOn: getRoleConfigurationValue(roundingConfigurations, RoundingSettings.MeetingUrl),
												isInviteViaSmsOn: getRoleConfigurationValue(roundingConfigurations, RoundingSettings.InviteViaSms),
												isDialInOn: getRoleConfigurationValue(roundingConfigurations, RoundingSettings.DialIn),
												isDialOutOn: getRoleConfigurationValue(roundingConfigurations, RoundingSettings.DialOut),
												isInviteViaEmailOn: getRoleConfigurationValue(roundingConfigurations, RoundingSettings.InviteViaEmail),
												isTranslationServicesOn: getRoleConfigurationValue(
													roundingConfigurations,
													RoundingSettings.TranslationServices
												),
												isCancelInviteOn: getRoleConfigurationValue(roundingConfigurations, RoundingSettings.CancelInvite),
											}}
											setFailedInvites={setFailedInvites}
											onDismiss={() =>
												conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_INVITE_PARTICIPANTS_MODAL)
											}
										/>
									)}
									{conferenceConfigs.isLiveCaptionsOpen && <SpeechToTextView />}
								</>
							)}
							<MainParticipantView
								ref={mainParticipantVideoRef}
								participant={mainParticipant}
								activeTrackType={activeTrackType}
							/>
						</div>
						<CallControlsView
							transferrableParticipants={transferrableParticipants}
							mainParticipant={mainParticipant}
							setCanScreenShare={setCanScreenShare}
						/>
					</StyledConference>
					{screenType.isSmall && conferenceParticipants.length >= 1 && getParticipantToDisplay() && (
						<PictureInPictureCameraView participant={getParticipantToDisplay()} activeTrackType={Cam} />
					)}
					{getUserRoleId() !== UserTypes.GUEST && <AssignedRequestConferenceFunctions isJoin={isJoin} />}
					{conferenceConfigs.isCECSupportedModalOpen && (
						<Modal
							onDismiss={() => conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_CEC_SUPPORTED_MODAL)}
							title={intl.formatMessage({ id: 'unSupportedTV' })}>
							<Modal.Content>
								<p>{translate('commandIsNotSupportedPatientTV')}</p>
							</Modal.Content>
							<Modal.Actions>
								<Button
									type='submit'
									variant={ButtonType.SUBMIT}
									onClick={() => conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_CEC_SUPPORTED_MODAL)}>
									Ok
								</Button>
							</Modal.Actions>
						</Modal>
					)}
					{conferenceConfigs.isTransferOwnershipModalOpen && (
						<Modal
							onDismiss={() => conferenceConfigs.setIsTransferOwnershipModalopen(false)}
							title={intl.formatMessage({ id: 'transferOwnership' })}>
							<Modal.Content>
								<p>{translate('transferOwnershipDescription')}</p>
							</Modal.Content>
							<Modal.Actions>
								<div className='transfer-ownership-actions'>
									<Button type='submit' variant={ButtonType.CANCEL} onClick={leaveConference}>
										{translate('leaveCall')}
									</Button>
									<Button type='submit' variant={ButtonType.SUBMIT} onClick={evt => leaveConference(evt, true)}>
										{translate('endCall')}
									</Button>
								</div>
							</Modal.Actions>
						</Modal>
					)}
					{canScreenShare && !canScreenShare[localParticipant.id] && (
						<Modal
							onDismiss={() => setCanScreenShare({ ...canScreenShare, [localParticipant.id]: true })}
							title={intl.formatMessage({ id: 'screenShareNotAllowed' })}>
							<Modal.Content>
								<p>{translate('screenSharingNotAllowed')}</p>
							</Modal.Content>
							<Modal.Actions>
								<Button type='submit' onClick={() => setCanScreenShare({ ...canScreenShare, [localParticipant.id]: true })}>
									Ok
								</Button>
							</Modal.Actions>
						</Modal>
					)}
					{conferenceConfigs.isStreamSettingsModalOpen && (
						<StreamSettingsView
							localParticipant={localParticipant}
							onDismiss={() => conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_STREAM_SETTINGS_MODAL)}
						/>
					)}
					<VirtualBackgroundProvider>
						{conferenceConfigs.isVirtualBackgroundModalOpen && (
							<VirtualBackgroundView
								onDismiss={() => conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_SELECT_BACKGROUND_MODAL)}
							/>
						)}
					</VirtualBackgroundProvider>
					{conferenceConfigs.removeParticipantModal.isOpen && (
						<RemoveParticipantModal
							display={true}
							onModalClose={() =>
								conferenceConfigs.setRemoveParticipantModal({ isOpen: false, modalMessage: '', onSubmit: () => {} })
							}
							primaryButtonLabel={intl.formatMessage({ id: 'removeParticipant' })}
							onModalSubmit={() => conferenceConfigs.removeParticipantModal.onSubmit()}
							position='center'>
							<form>
								<h4>{intl.formatMessage({ id: 'confirmParticipantRemoval' })}</h4>
								<p>{conferenceConfigs.removeParticipantModal.modalMessage}</p>
							</form>
						</RemoveParticipantModal>
					)}
				</>
			)}
			{getUserRole() === UserRoles.VISITOR && <VisitorContainer />}
			{getUserRoleId() !== UserTypes.GUEST && (
				<HealthDataContainer
					mainParticipant={mainParticipant}
					submitVisit={submitVisit}
					roomId={findSectorById(healthSystems.treeData.tree, mainParticipant.objectId)?.roomId}
				/>
			)}
			{getUserRole() === UserRoles.NURSE && helloParticipant?.objectId && conferenceConfigs?.isAiOpen && (
				<RoundingAi
					helloDeviceId={helloParticipant?.objectId}
					isAiOpen={conferenceConfigs?.isAiOpen}
					isDarkMode={conferenceConfigs?.isDarkMode}
					isConference={true}
				/>
			)}
			<Toaster messages={conferenceConfigs.conferenceErrorMessages} />
			<Alert display={trasnferredOwnerMessage} fixed message={trasnferredOwnerMessage} variant='dark' />
		</div>
	);
};

export default ConferenceCall;
